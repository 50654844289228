<template>
  <div>
    <div class="pb-3">
      <!-- {{ fixtures[0] }} -->
      <div class="bg-primary py-2 px-4 mb-3 rounded">
        <h6 class="m-0 text-white">
          {{ $t("UPCOMING_EVENT_IN_FOOTBALL") }}
        </h6>
      </div>

      <a
        class="card text-center mb-4"
        id="treeLeaves"
        style="cursor: pointer; text-decoration: none"
        :href="`/viewmatch?id=${data.id}&league_id=${data.leagueId}`"
        v-for="data in fixtures"
        :key="data"
        ><p class="card-text text-left m-2">
          <img
            class="card-img mr-1 bg-light rounded"
            :src="data.leagueLogoUrl"
            alt="Card image"
            style="width: 20px; height: 20px; object-fit: cover"
          />
          {{ data.leagueName }}
        </p>
        <div class="d-flex justify-content-between">
          <h5 class="m-2">{{ data.homeTeamName }}</h5>

          <h5 class="m-2">{{ data.awayTeamName }}</h5>
        </div>
        <p
          class="align-items-center justify-content-center h4 m-0 text-decoration-none"
        >
          {{ timeReturn(data.startAt) }}
        </p>
        <div class="d-flex justify-content-around">
          <img
            class="m-2"
            :src="data.homeTeamLogoUrl"
            style="width: 40px; height: 40px; object-fit: cover"
          />

          VS

          <img
            class="m-2"
            :src="data.awayTeamLogoUrl"
            style="width: 40px; height: 40px; object-fit: cover"
          />
        </div>
        <span class="badge badge-primary"
          >{{ data.totalZianPrediction }} {{ $t("TIPS") }}
        </span>

        <!-- <div class="card-body">
          <p class="card-title">
            {{ data.homeTeamName }}
            <img
              class="m-2"
              :src="data.homeTeamLogoUrl"
              style="width: 40px; height: 40px; object-fit: cover"
            />
            VS

            <img
              class="m-2"
              :src="data.awayTeamLogoUrl"
              style="width: 40px; height: 40px; object-fit: cover"
            />
            {{ data.awayTeamName }}
          </p>
        </div> -->
      </a>
      <!-- <div class="position-relative" style="width: 100px">
          <p
            class="overlay align-items-center justify-content-center h4 m-0 text-decoration-none"
            style="background-color: white"
          >
            15:00
          </p>
        </div>

        <div
          class="w-100 d-flex flex-column justify-content-center bg-light"
          style="height: 100px"
        >
          <div class="d-flex justify-content-between pr-2">
            <img
              class="m-2"
              :src="data.homeTeamLogoUrl"
              style="width: 25px; height: 25px; object-fit: cover"
            />
            <p class="h6 m-0">{{ data.homeTeamName }}</p>
            <span></span>
          </div>
          <div class="d-flex justify-content-between pr-2">
            <img
              class="m-2"
              :src="data.awayTeamLogoUrl"
              style="width: 25px; height: 25px; object-fit: cover"
            />
            <p class="h6 m-0">{{ data.awayTeamName }}</p>
            <span class="badge badge-primary"
              >{{ data.totalZianPrediction }} {{ $t("TIPS") }}</span
            >
          </div>
        </div> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {
    let url = new URL(window.location.href);
    let param = url.searchParams.get("id");
    this.$http
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/fixtures/upcoming`, {
        params: { league_id: param },
      })
      .then(({ data }) => {
        this.fixtures = data.data.fixtures;
        this.date = data.data.date;
      })
      .catch((err) => {
        return;
      });
  },
  data() {
    return {
      fixtures: {},
      date: {},
    };
  },
  methods: {
    timeReturn(val) {
      return (
        new Date(val).toTimeString().split(" ")[0].split(":")[0] +
        ":" +
        new Date(val).toTimeString().split(" ")[0].split(":")[1]
      );
    },
  },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
a {
  font-size: 14px;
}
table {
  padding: 20px;
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}
#treeLeaves {
  transition: transform 0.2s; /* Animation */
}
#treeLeaves:hover {
  transform: scale(1.03);
}
</style>
