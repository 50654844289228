<template>
  <div>
    <div
      class="d-flex align-items-center justify-content-between py-2 px-4 mb-3 bg-primary rounded"
    >
      <p class="m-0 text-white">
        {{ $t("MOST_PLAYED_MATCHES_FOR_FOOTBALL") }}
      </p>
    </div>

    <div class="card text-center mb-4" v-for="data in top_tips" :key="data">
      <div class="card-body">
        <p class="card-title">
          {{ data.homeTeamName }}
          <img
            class="m-2"
            :src="data.homeTeamLogoUrl"
            style="width: 20px; height: 20px; object-fit: cover"
          />
          VS
          <img
            class="m-2"
            :src="data.awayTeamLogoUrl"
            style="width: 20px; height: 20px; object-fit: cover"
          />
          {{ data.awayTeamName }}
        </p>

        <button type="button" class="btn btn-primary btn-block">
          {{ data.totalZianPrediction }} TIPS FOR MATCH
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {
    this.$http
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/fixtures/top-prediction`)
      .then(({ data }) => {
        this.top_tips = data.data;
        console.log("top_tips", this.top_tips);
      })
      .catch((err) => {
        return;
      });
  },
  data() {
    return {
      top_tips: {},
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
a {
  font-size: 14px;
}
table {
  padding: 20px;
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}
</style>
