<template>
  <div>
    <div class="container-fluid py-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <Upcoming />
          </div>
          <div class="col-lg-4">
            <MostPlayed />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Upcoming from "../components2/Upcoming.vue";
import MostPlayed from "../components2/MostPlayed.vue";

export default {
  components: { Upcoming, MostPlayed },
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
a {
  font-size: 14px;
}
table {
  padding: 20px;
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}
</style>
